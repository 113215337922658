









































































import {
  Component,
  Vue,
  Prop,
  Emit,
} from 'vue-property-decorator';
import AuthService from '@/services/AuthService';

const authService = new AuthService();

@Component
export default class ChangePasswordDialog extends Vue {
  @Prop({ type: Boolean, required: true }) open: boolean|undefined;

  public loading: boolean = false;

  public oldPassword: string = '';

  public newPassword: string = '';

  public confirmNewPassword: string = '';

  get dialog() {
    return this.open;
  }

  @Emit('close-dialog')
  // eslint-disable-next-line class-methods-use-this
  close() {
    return false;
  }

  async changePassword() {
    try {
      this.loading = true;
      await authService.changePassword(
        this.oldPassword,
        this.newPassword,
        this.confirmNewPassword,
      );
      this.loading = false;
      this.$store.commit('Snackbar/showNotification', { message: 'เปลี่ยนรหัสผ่านแล้ว', error: false });
      this.close();
    } catch (e) {
      this.$store.commit('Snackbar/showNotification', { message: e.message, error: true });
      this.loading = false;
    }
  }
}
